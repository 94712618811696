import React, {useEffect, useState} from "react";
import './ChartPage.scss';
import '../sidebar/SidebarItem.scss';
import '../mainpage/SmallNewsTile.scss';
import '../mainpage/NewsTile.scss';
import {Helmet} from "react-helmet";
import type {News} from "../model/News";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import SidebarItem from "../sidebar/SidebarItem";
import StockApi from "../../myApis/stockApi";
import {CanvasJSChart} from "./CanvasJS";
import InfoCard from "./InfoCard";
import PriceImgBig from "../../assets/images/finance/fin4.png";
import VolumeImgBig from "../../assets/images/finance/fin8.png";
import CapImgBig from "../../assets/images/finance/fin2.svg";
import SupplyImgBig from "../../assets/images/finance/fin5.png";
import TopCoins from "../sidebar/TopCoins";

type ChartPageProps = {
    path: string,
    pageContext: {
        topic: string,
        pair: string,
        recentNews: News[],
    }
}

function ChartPage(props: ChartPageProps) {
    const {recentNews, topic, pair} = props.pageContext;
    const [chartState, setChartState] = useState({
        data24h: null,
        data5m: null,
        data1h: null,
        mode: 24,
        loaded: false,
        isPrice: null,
        marketCap: null,
        dilutedMarketCap: null,
        dominance: null,
        volume24h: null,
        volumeChange24h: null,
        supply: null,
        circleSupply: null,
        maxSupply: null,
        cmcRanking: null,
        dateAdded: null,
        markets: null,
        price1h: null,
        price24h: null,
        price7d: null,
        price30d: null,
        price60d: null,
        price90d: null,
    })
    const swapChart = (mode) => {
        if (chartState.mode !== mode) {
            setChartState({...chartState, mode: mode});
        }
    };
    const oneDay = 3600 * 24 * 1000;
    const priceFractions = !chartState.isPrice ? 0
        : (chartState.isPrice > 1000 ? 0 : (chartState.isPrice > 0.1 ? 2 : (
            chartState.isPrice > 0.01 ? 3 : (chartState.isPrice > 0.0001 ? 5 : 8)
        )));
    const formatMoney = (it, fractions = priceFractions) => {
        return new Intl.NumberFormat("en", {style: 'currency', currency: "USD", minimumFractionDigits: fractions}).format(it)
    }
    const options = {
        theme: "light2",
        animationEnabled: true,
        zoomEnabled: true,
        exportEnabled: false,
        axisX: {
            valueFormatString:  chartState.mode === 24 ? "DD MMM" : (chartState.mode === 5 ? "HH:mm DD MMM" : "DD MMM"),
            viewportMinimum: chartState.mode === 24 ? Date.now() - 400 * oneDay : (chartState.mode === 5 ? Date.now() - 2 * oneDay : Date.now() - 20 * oneDay),
        },
        axisY: {
            prefix: "$",
            title: "Price (in USD)",
            includeZero: false,
        },
        data: [{
            type: "ohlc",
            color: "#FF6501",
            showInLegend: true,
            name: `${topic} ${chartState.mode === 24 ? "Daily" : (chartState.mode === 5 ? "5 Minute" : "Hourly")} Candle Chart`,
            yValueFormatString: "$###0.00",
            xValueFormatString:  chartState.mode === 24 ? "DD MMMM YYYY" : (chartState.mode === 5 ? "HH:mm DD MMM" : "HH:mm DD MMM"),
            xValueType: "dateTime",
            dataPoints: chartState.mode === 24 ? chartState.data24h : (chartState.mode === 5 ? chartState.data5m :  chartState.data1h)
        }]
    }
    useEffect(() => {
        const stockApi = new StockApi();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        stockApi.getCoinData(pair)
            .then(it => setChartState({
                data24h: it.OHLC24h ? JSON.parse(it.OHLC24h) : null,
                data1h: it.OHLC1h ? JSON.parse(it.OHLC1h) : null,
                data5m: it.OHLC5min ? JSON.parse(it.OHLC5min) : null,
                mode: 24,
                loaded: true,
                isPrice: it.isPrice ? Number.parseFloat(it.isPrice) : null,
                marketCap: it.marketCap ? Number.parseFloat(it.marketCap) : null,
                dilutedMarketCap: it.dilutedMarketCap ? Number.parseFloat(it.dilutedMarketCap) : null,
                dominance: it.dominance ? Number.parseFloat(it.dominance) : null,
                volume24h: it.volume24h ? Number.parseFloat(it.volume24h) : null,
                volumeChange24h: it.volumeChange24h ? Number.parseFloat(it.volumeChange24h) : null,
                supply: it.supply ? Number.parseFloat(it.supply) : null,
                circleSupply: it.circleSupply ? Number.parseFloat(it.circleSupply) : null,
                maxSupply: it.maxSupply ? Number.parseFloat(it.maxSupply) : null,
                cmcRanking: it.cmcRanking,
                dateAdded: it.dateAdded,
                markets: it.markets,
                price1h: it.price1h ? Number.parseFloat(it.price1h) : null,
                price24h: it.price24h ? Number.parseFloat(it.price24h) : null,
                price7d: it.price7d ? Number.parseFloat(it.price7d) : null,
                price30d: it.price30d ? Number.parseFloat(it.price30d) : null,
                price60d: it.price60d ? Number.parseFloat(it.price60d) : null,
                price90d: it.price90d ? Number.parseFloat(it.price90d) : null,
            }))
            .catch(e => console.log(e));
    }, [pair, setChartState]);

    return <div className={"chartPage"}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`BtcLeaks | ${topic} Chart, Price, Volume, MarketCap`}</title>
                <link rel="canonical" href={"https://btcleaks.com" + props.path} />
                <meta name="description" content={`Chart, price, volume, market-cap and other stats for: ${topic}`}/>
                <meta name="keywords" content={`${topic}, Chart, Volume, Price, MarketCap`}/>
                <meta property="og:title" content={`BtcLeaks | ${topic} Chart, Price, Volume, MarketCap`} />
                <meta property="og:description" content={`Chart, price, volume, market-cap and other stats for: ${topic}`}/>
                <meta property="og:image" content={`http://btcleaks.com/img_00242.png`} />
                <meta property="og:image:alt" content={"BtcLeaks.com"} />
                <meta property="og:image:secure_url" content="https://btcleaks.com/img_00242.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:url" content={`https://btcleaks.com${props.path}`} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="530" />
                <meta property="og:site.name" content={"btcLeaks.com"} />
                <meta property="article:publisher" content="https://btcLeaks.com"/>
                <meta property="article:author" content="BtcLeaks.com"/>
                <meta name="twitter:site" content="@BtcLeaks" />
                <meta name="twitter:creator" content="Saitama" />
                <meta name="twitter:card" content="summary"/>
                <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "Chart, price, volume, market-cap and other stats for: ${topic}",
                    "dateModified": "${new Date().toISOString()}",
                    "datePublished": "${new Date().toISOString()}",
                    "headline": "BtcLeaks | ${topic} Chart, Price, Volume, MarketCap",
                    "@context": "http://schema.org",
                    "@type": "Article",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com/img_00242.png"
                        }   
                    }
                }`
                }</script>
            </Helmet>
            <article className={"contentWrapper"}>
                <section className={"column0"}>
                    <div className={"content"}>
                        <div id={"chart"}>
                            <h1>{topic} Chart</h1>
                            <div className={"chartWrapper"} id={"chartWrapper"}>
                                {chartState.loaded &&
                                    ((typeof window !== undefined) ? <CanvasJSChart options={options}/> : <div/>)
                                }
                            </div>
                        </div>
                        <div className={"chartControls"}>
                            {chartState.data5m && <button onClick={() => swapChart(5)}>5 Minute Candles</button>}
                            {chartState.data1h && <button onClick={() => swapChart(1)}>1 Hour Candles</button>}
                            <button onClick={() => swapChart(24)}>1 Day Candles</button>
                        </div>
                    </div>
                </section>

                <section className={"column1"}>
                    <div className={"content"}>
                        <div id={"market"}>
                            <h2>{topic} Live Data</h2>
                            <div className={"liveData"}>
                                {chartState.markets && <p className={"simpleText"}>
                                    That is an average data from {chartState.markets} different markets.
                                </p>}
                                <InfoCard title={"Price"} image2={PriceImgBig}>
                                    One coins is worth {formatMoney(chartState.isPrice)}.{" "}
                                    {chartState.price24h && <span>{`It made ${chartState.price24h.toFixed(2)}% since yesterday (${formatMoney(chartState.isPrice / (1 + chartState.price24h / 100))}). `}</span>}
                                    {chartState.price7d && <span>{`${chartState.price7d.toFixed(2)}% since last week (${formatMoney(chartState.isPrice / (1 + chartState.price7d / 100))}). `}</span>}
                                    {chartState.price30d && <span>{`And ${chartState.price30d.toFixed(2)}% since last month (${formatMoney(chartState.isPrice / (1 + chartState.price30d / 100))}).`}</span>}
                                </InfoCard>
                                {chartState.volume24h &&
                                    <InfoCard title={"Trading Volume"} image2={VolumeImgBig} className={"volumeCard"}>
                                        The {topic} 24 hour trading volume was {formatMoney(chartState.volume24h, 0)}.
                                        {chartState.volumeChange24h && <span>{" "}
                                            Compared to yesterday the trading volume {" "}
                                            {chartState.volumeChange24h > 0 ? "increased" : "decreased"} by {chartState.volumeChange24h.toFixed(2)}%
                                        </span>}
                                    </InfoCard>
                                }
                                {chartState.marketCap &&
                                    <InfoCard title={"Market Capitalization"} image2={CapImgBig}>
                                        The {topic} market capitalisation is at {formatMoney(chartState.marketCap,0)}.
                                        {(chartState.dilutedMarketCap || chartState.cmcRanking) && <span>
                                            {chartState.dilutedMarketCap && <span> While the diluted marketCap equals {formatMoney(chartState.dilutedMarketCap,0)}.</span>}
                                            {chartState.cmcRanking && <span> That is rank {chartState.cmcRanking} of all cryptocurrencies. </span>}
                                        </span>}
                                    </InfoCard>
                                }
                                {chartState.supply &&
                                    <InfoCard title={"Supply"} image2={SupplyImgBig}>
                                        The {topic} total supply is right now {chartState.supply} coins.
                                        {(chartState.circleSupply || chartState.maxSupply) && <span>
                                            {chartState.circleSupply && <span> The circulating supply equals {chartState.circleSupply} coins.</span>}
                                            {chartState.maxSupply && <span> While the supply is limited to maximal {chartState.maxSupply} coins.</span>}
                                        </span>}
                                </InfoCard>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className={"column2"}>
                    <div className={"sidebar"}>
                        <div className={"sidebarGroup"}>
                            <div className={"sidebarHeadline"}>

                            </div>
                            <div className={"fearAndGreed"}>
                                <img src="https://alternative.me/crypto/fear-and-greed-index.png"
                                     alt="Bitcoin Fear and Greed Index"/>
                            </div>
                        </div>
                    </div>
                    <TopCoins/>
                    <div className="sidebar">
                        <div className="sidebarGroup">
                            <ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-6013495598121737"
                                 data-ad-slot="9302285469"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>
                        </div>
                    </div>
                    <div className="sidebar">
                        <div className="sidebarGroup">
                            {recentNews.length > 0 &&
                                <div className={"sidebarHeadline"}>
                                    <a href={"/"} title={"All crypto news"} className={"mainLink"}>
                                        <span>News</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                                    </a>
                                </div>}
                            {recentNews
                                .map((it, index) => <SidebarItem news={it} key={"side3_" + index}/>)}
                        </div>
                    </div>
                </section>
            </article>
        </div>;

}

export default ChartPage;
