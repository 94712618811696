// @flow
import React, {useEffect, useState} from "react";
import {toLink} from "../../helper/stringUtils";
import StockApi from "../../myApis/stockApi";
import "./TopCoins.scss";

function TopCoins() {
    const [tops, setTops] = useState(null);
    useEffect(() => {
        const api = new StockApi();
        api.getTopCoins()
            .then(res => setTops(res));
    }, []);

    return tops
        ? <div className="topCoins">
            <div className="headline">
                Top Coins
            </div>
            <div className={"subHeadline"}>24h Price Change</div>
            {tops.filter(it => it.price !== 0)
                .filter(it => it.price !== null && it.icon != null && it.name != null)
                .reverse()
                .map(it => <div className={"priceItem"}>
                    <a href={"/chart/" + toLink(it.name)} title={it.name + " Market Data"}>
                    <div>
                        <img src={it.icon} alt={it.name} title={it.name}/>
                        <span>{it.name}</span>
                        <span> ({it.coin})</span>
                    </div>
                    </a>
                    <div className={"value"}>{it.value > 0 ? "+" : ""}{it.value.toFixed(2)}%</div>
                </div>)
            }
            <div className={"subHeadline"}>24h Trading Volume</div>
            {tops.filter(it => it.volume !== 0)
                .filter(it => it.volume !== null && it.icon != null && it.name != null)
                .reverse()
                .map(it => <div className={"volumeItem"}>
                    <a href={"/chart/" + toLink(it.name)} title={it.name + " Market Data"}>
                        <div>
                            <img src={it.icon} alt={it.name} title={it.name}/>
                            <span>{it.name}</span>
                            <span> ({it.coin})</span>
                        </div>
                    </a>
                    <div className={"value"}>${(it.value / 1000 / 1000).toFixed(0)}kk</div>
                </div>)
            }
            <div className={"subHeadline"}>Market Cap</div>
            {tops.filter(it => it.marketCap !== 0)
                .filter(it => it.marketCap !== null && it.icon != null && it.name != null)
                .reverse()
                .map(it => <div className={"marketCapItem"}>
                    <a href={"/chart/" + toLink(it.name)} title={it.name + " Market Data"}>
                        <div>
                            <img src={it.icon} alt={it.name} title={it.name}/>
                            <span>{it.name}</span>
                            <span> ({it.coin})</span>
                        </div>
                    </a>
                    <div className={"value"}>${(it.value / 1000000).toFixed(0)}kk</div>
                </div>)
            }
        </div>
        : <div/>;
}

export default TopCoins;
