import React from "react";
import './InfoCard.scss';

type InfoCardProps = {
    children: any,
    title: any,
    image1: any,
    image2: any,
    className: string,
}

function InfoCard(props: InfoCardProps) {
    const {children, image1, image2, title, className} = props;


    return <div className={"infoCard " + className || ""}>
        <div className="outerCard">
            <div className="innerCard">
                <div className="content1">
                    {image1 && <img src={image1} alt={title} className="imgSmall"/>}
                        <h3>{title}</h3>
                        <p>{children}</p>
                </div>
                <div className="content2">
                    <img src={image2} alt={title}
                         className="imgBig"/>
                </div>
            </div>
        </div>
    </div>
;

}

export default InfoCard;
